var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12"}},[_c('b-form-select',{attrs:{"options":_vm.userSiteCollection},on:{"change":_vm.changeSite},model:{value:(_vm.selected_site_id),callback:function ($$v) {_vm.selected_site_id=$$v},expression:"selected_site_id"}})],1),_c('ul',{staticClass:"menu-nav"},[(_vm.permissionCan('module.pages.menu') && _vm.moduleIsEnabled('Pages'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/cms/page') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-edit-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PAGE_ADMINISTARATION")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('pages.page.menu') && _vm.selected_site_id > 0)?_c('router-link',{attrs:{"to":"/cms/pages/pages"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PAGES")))])])])]}}],null,false,3072108325)}):_vm._e(),(
              _vm.permissionCan('pages.pageTemplate.menu') && _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/pages/pageTemplates"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-file-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PAGE_TEMPLATES")))])])])]}}],null,false,2665988497)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(_vm.permissionCan('module.blog.menu') && _vm.moduleIsEnabled('Blog'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/cms/blog') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-list"}),_c('span',{staticClass:"menu-text"},[_vm._v("Blog")]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('blog.post.menu'))?_c('router-link',{attrs:{"to":"/cms/blog/posts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-edit"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.POSTS")))])])])]}}],null,false,3729525410)}):_vm._e(),(_vm.permissionCan('blog.category.menu'))?_c('router-link',{attrs:{"to":"/cms/blog/categories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-3"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.BLOG_CATEGORIES")))])])])]}}],null,false,3705019265)}):_vm._e(),(_vm.permissionCan('blog.tag.menu'))?_c('router-link',{attrs:{"to":"/cms/blog/tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-tag"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.TAGS")))])])])]}}],null,false,3706709876)}):_vm._e(),(_vm.permissionCan('blog.setting.menu'))?_c('router-link',{attrs:{"to":"/cms/blog/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(
        _vm.permissionCan('module.products.menu') && _vm.moduleIsEnabled('Products')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/products') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-list"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PRODUCTS")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('products.product.menu'))?_c('router-link',{attrs:{"to":"/products/products"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-open-box"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PRODUCTS")))])])])]}}],null,false,2491066401)}):_vm._e(),(_vm.permissionCan('products.category.menu'))?_c('router-link',{attrs:{"to":"/products/productCategories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-folder"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.CATEGORIES")))])])])]}}],null,false,4003287541)}):_vm._e(),(_vm.permissionCan('products.property.menu'))?_c('router-link',{attrs:{"to":"/products/properties"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-confetti"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PROPERTIES")))])])])]}}],null,false,912873916)}):_vm._e(),(_vm.permissionCan('products.price_list.menu') || 1)?_c('router-link',{attrs:{"to":"/products/pricelists"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-price-tag"}),_c('span',{staticClass:"menu-text"},[_vm._v("Price List")])])])]}}],null,false,1400334313)}):_vm._e(),(_vm.permissionCan('products.setting.menu'))?_c('router-link',{attrs:{"to":"/products/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(
        _vm.permissionCan('module.webshop.menu') && _vm.moduleIsEnabled('Webshop')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/webShop') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-folder-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("WebShop")]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('products.price_list.menu') || 1)?_c('router-link',{attrs:{"to":"/webShop/orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-piggy-bank"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("WEBSHOP.orders")))])])])]}}],null,false,3677797508)}):_vm._e(),(_vm.permissionCan('products.price_list.menu') || 1)?_c('router-link',{attrs:{"to":"/webShop/coupons"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-gift"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("COUPONS.coupons")))])])])]}}],null,false,2559641818)}):_vm._e(),(_vm.permissionCan('products.price_list.menu') || 1)?_c('router-link',{attrs:{"to":"/webShop/customers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("WEBSHOP.customers")))])])])]}}],null,false,1723548679)}):_vm._e(),(_vm.permissionCan('webshop.setting.menu'))?_c('router-link',{attrs:{"to":"/webShop/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.news.menu') && _vm.moduleIsEnabled('News'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/cms/news') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-list-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.NEWS_EDITOR")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(
              _vm.permissionCan('news.frontPageEditor.menu')
            )?_c('router-link',{attrs:{"to":"/cms/news/frontPageEditor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-website"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.FRONTENT_PAGE_EDITOR")))])])])]}}],null,false,164812112)}):_vm._e(),(
              _vm.permissionCan('news.mainPageEditor.menu')
            )?_c('router-link',{attrs:{"to":"/cms/news/mainPageEditor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-website"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.MAIN_PAGE_EDITOR")))])])])]}}],null,false,406896709)}):_vm._e(),(_vm.permissionCan('news.news.menu'))?_c('router-link',{attrs:{"to":"/cms/news/news"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-edit"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.POSTS")))])])])]}}],null,false,3729525410)}):_vm._e(),(_vm.permissionCan('news.category.menu'))?_c('router-link',{attrs:{"to":"/cms/news/categories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-3"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.NEWS_CATEGORIES")))])])])]}}],null,false,3942081352)}):_vm._e(),(_vm.permissionCan('news.tag.menu'))?_c('router-link',{attrs:{"to":"/cms/news/tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-tag"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.TAGS")))])])])]}}],null,false,3706709876)}):_vm._e(),(_vm.permissionCan('news.author.menu'))?_c('router-link',{attrs:{"to":"/cms/news/authors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.newsAuthors")))])])])]}}],null,false,1311253695)}):_vm._e(),(_vm.permissionCan('news.setting.menu'))?_c('router-link',{attrs:{"to":"/cms/news/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(
            _vm.permissionCan('module.competition_calendar.menu') &&
            _vm.selected_site_id > 0 &&
            _vm.moduleIsEnabled('CompetitionCalendar')
          )?_c('router-link',{attrs:{"to":"/cms/competitionCalendar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-calendar-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.COMPETITION_CALENDAR")))])])])]}}],null,false,2624800530)}):_vm._e(),(
        _vm.permissionCan('module.publication.menu') &&
        _vm.moduleIsEnabled('Publications')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/cms/publications'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-shield"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PUBLICATIONS")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('publication.post.menu'))?_c('router-link',{attrs:{"to":"/cms/publications/publications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-edit"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PUBLICATION")))])])])]}}],null,false,4197381621)}):_vm._e(),(_vm.permissionCan('publication.category.menu'))?_c('router-link',{attrs:{"to":"/cms/publications/publicationCategories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-3"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PUBLICATION_CATEGORIES")))])])])]}}],null,false,2328186907)}):_vm._e(),(_vm.permissionCan('publication.setting.menu'))?_c('router-link',{attrs:{"to":"/cms/publications/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(_vm.permissionCan('system.mediaLibrary.menu') && _vm.selected_site_id > 0)?_c('router-link',{attrs:{"to":"/cms/media_library"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-folder"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.MEDIA_LIBRARY")))])])])]}}],null,false,1742852629)}):_vm._e(),(
        _vm.permissionCan('module.galleries.menu') && _vm.moduleIsEnabled('Galleries')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/cms/galleries') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-squares"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.GALLERIES")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(
              _vm.permissionCan('galleries.category.menu') && _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/galleries"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-squares"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.GALLERIES")))])])])]}}],null,false,2121136489)}):_vm._e(),(_vm.permissionCan('galleries.setting.menu'))?_c('router-link',{attrs:{"to":"/cms/galleries/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(
        _vm.permissionCan('pages.menu.menu') &&
        _vm.selected_site_id > 0 &&
        _vm.moduleIsEnabled('Pages')
      )?_c('router-link',{attrs:{"to":"/cms/menus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-menu-3"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.MENUS")))])])])]}}],null,false,4030680298)}):_vm._e(),(
        (_vm.permissionCan('module.banner.menu') ||
          _vm.permissionCan('pages.banner.menu')) &&
        _vm.moduleIsEnabled('Pages')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/cms/banners') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-speech-bubble"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.BANNERS")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('pages.banner.menu') && _vm.selected_site_id > 0)?_c('router-link',{attrs:{"to":"/cms/banners/banners"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-chat-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.banners")))])])])]}}],null,false,1417496944)}):_vm._e(),(
              _vm.permissionCan('pages.bannerCampaign.menu') &&
              _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/banners/bannerCampaigns"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-calendar"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.bannerCampaigns")))])])])]}}],null,false,4207281662)}):_vm._e(),(
              _vm.permissionCan('pages.bannerClient.menu') && _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/banners/bannerClients"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-user"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.bannerClients")))])])])]}}],null,false,1393586682)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(
        _vm.permissionCan('contactForms.contactForm.menu') &&
        _vm.selected_site_id > 0 &&
        _vm.moduleIsEnabled('ContactForms')
      )?_c('router-link',{attrs:{"to":"/cms/contactForms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-multimedia-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.contact_form")))])])])]}}],null,false,3578566414)}):_vm._e(),(
        _vm.permissionCan('module.quotations.menu') &&
        _vm.moduleIsEnabled('Quotations')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/cms/quotations'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-hangouts-logo"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.QUOTATIONS")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(
              _vm.permissionCan('quotations.quotation.menu') &&
              _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/quotations/quotations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-sheet"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.quotations")))])])])]}}],null,false,568998649)}):_vm._e(),(
              _vm.permissionCan('quotations.quotationCategory.menu') &&
              _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/quotations/quotationCategories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-files-and-folders"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.QUOTATION_CATEGORIES")))])])])]}}],null,false,1193751063)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(
        _vm.permissionCan('logos.category.menu') &&
        _vm.selected_site_id > 0 &&
        _vm.moduleIsEnabled('Logos')
      )?_c('router-link',{attrs:{"to":"/cms/logos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-image-file"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.LOGOS")))])])])]}}],null,false,519700243)}):_vm._e(),(_vm.permissionCan('module.career.menu') && _vm.moduleIsEnabled('Career'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/cms/career'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-rocket"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.CAREER")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('career.career.menu') && _vm.selected_site_id > 0)?_c('router-link',{attrs:{"to":"/cms/career/career"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-notes"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.POSITIONS")))])])])]}}],null,false,3758300860)}):_vm._e(),(_vm.permissionCan('career.setting.menu'))?_c('router-link',{attrs:{"to":"/cms/career/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(
        _vm.permissionCan('module.external_references.menu') &&
        _vm.moduleIsEnabled('ExternalReferences')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/cms/externalReferences'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-clip-symbol"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.EXTERNAL_REFERENCES")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(
              _vm.permissionCan('external_references.category.menu') &&
              _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/externalReferences"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-clip-symbol"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.EXTERNAL_REFERENCES")))])])])]}}],null,false,384095840)}):_vm._e(),(_vm.permissionCan('external_references.setting.menu'))?_c('router-link',{attrs:{"to":"/cms/externalReferences/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(_vm.permissionCan('module.faq.menu') && _vm.moduleIsEnabled('FAQ'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/cms/faq'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-clip-symbol"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.FAQ")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('faq.category.menu') && _vm.selected_site_id > 0)?_c('router-link',{attrs:{"to":"/cms/faq"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-clip-symbol"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.FAQ")))])])])]}}],null,false,2290917516)}):_vm._e(),(_vm.permissionCan('faq.setting.menu'))?_c('router-link',{attrs:{"to":"/cms/faq/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(
        _vm.permissionCan('module.colleagues.menu') &&
        _vm.moduleIsEnabled('Colleagues')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/cms/colleagues'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-user"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.COLLEAGUES_ADMINISTRATION")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(
              _vm.permissionCan('colleagues.colleague.menu') &&
              _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/colleagues/colleagues"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.COLLEAGUES")))])])])]}}],null,false,2340958363)}):_vm._e(),(
              _vm.permissionCan('colleagues.colleagueCategory.menu') &&
              _vm.selected_site_id > 0
            )?_c('router-link',{attrs:{"to":"/cms/colleagues/colleagueCategories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-layers"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.COLLEAGUE_CATEGORIES")))])])])]}}],null,false,585492851)}):_vm._e(),(_vm.permissionCan('colleagues.setting.menu'))?_c('router-link',{attrs:{"to":"/cms/colleagues/settings/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))])])])]}}],null,false,598445961)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(_vm.permissionCan('module.gdpr.menu') && _vm.moduleIsEnabled('Gdpr'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/cookieManagement'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.selected_site_id > 0)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.SETTINGS")))]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),(_vm.selected_site_id > 0)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('gdpr.code.menu'))?_c('router-link',{attrs:{"to":"/cms/cookieManagement/cookies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-list-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.COOKIES")))])])])]}}],null,false,633330015)}):_vm._e(),(_vm.permissionCan('gdpr.category.menu') && _vm.selected_site_id > 0)?_c('router-link',{attrs:{"to":"/cms/cookieManagement/cookieCategories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-indent-dots"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.COOKIE_CATEGORIES")))])])])]}}],null,false,524859511)}):_vm._e(),(_vm.permissionCan('system.site.menu') && _vm.selected_site_id > 0)?_c('router-link',{attrs:{"to":"/cms/cookieManagement/settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.PAGE_SETTINGS")))])])])]}}],null,false,1584991865)}):_vm._e()],1)]):_vm._e()]):_vm._e(),(_vm.permissionCan('system.user.menu') && _vm.selected_site_id == 0)?_c('router-link',{attrs:{"to":"/settings/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.users")))])])])]}}],null,false,573815880)}):_vm._e(),(_vm.permissionCan('system.userGroup.menu') && _vm.selected_site_id == 0)?_c('router-link',{attrs:{"to":"/settings/userGroups"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-group"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.userGroups")))])])])]}}],null,false,3348139525)}):_vm._e(),(_vm.permissionCan('system.role.menu') && _vm.selected_site_id == 0)?_c('router-link',{attrs:{"to":"/settings/roles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-8"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.roles")))])])])]}}],null,false,2417207970)}):_vm._e(),(_vm.permissionCan('system.currency.menu') && _vm.selected_site_id == 0)?_c('router-link',{attrs:{"to":"/settings/currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-coins"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.currencies")))])])])]}}],null,false,1828154508)}):_vm._e(),(_vm.permissionCan('system.country.menu') && _vm.selected_site_id == 0)?_c('router-link',{attrs:{"to":"/settings/country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-earth-globe"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.countries")))])])])]}}],null,false,324092607)}):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }